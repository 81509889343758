<template>
  <div
    class="flex w-full flex-col justify-between gap-4 pt-3 text-left @md:flex-row @md:items-center @md:gap-0"
  >
    <div class="flex flex-col gap-2">
      <label for="today">{{ $t('auction-list.today') }}</label>
      <HeadlessSwitch
        id="today"
        v-model="isToday"
        :class="isToday ? 'bg-primary' : 'bg-gray-200'"
        class="relative inline-flex h-6 w-11 items-center rounded-full"
      >
        <span
          :class="isToday ? 'translate-x-6' : 'translate-x-1'"
          class="inline-block h-4 w-4 transform rounded-full bg-white transition"
        />
      </HeadlessSwitch>
    </div>

    <div class="flex flex-wrap gap-2">
      <div class="flex flex-col gap-1">
        <label for="from">{{ $t('auction-list.from') }}</label>
        <input
          id="from"
          v-model.lazy="filter.startDateRange.dateFrom"
          :max="filterData.startDate.maxDate"
          :min="minFromDate"
          type="date"
          class="rounded-md"
        />
      </div>
      <div class="flex flex-col gap-1">
        <label for="to">{{ $t('auction-list.to') }}</label>
        <input
          id="to"
          v-model.lazy="filter.startDateRange.dateTo"
          :max="filterData.startDate.maxDate"
          :min="minToDate"
          type="date"
          class="rounded-md"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useAuctionStore } from '@autobid/nuxt-pinia-store/store/useAuctionStore'
import type { ParsedFilterData } from '@autobid/strapi-integration/typescript/FilterData'
import { formatDateToInputFormat } from '@autobid/ui/utils/date/formatDateToInputFormat'

const auctionStoreKey = inject('auctionStoreKey')
const { filter } = storeToRefs(useAuctionStore(auctionStoreKey))

const filterData = inject('filterData') as Ref<ParsedFilterData>

const isToday = computed({
  get() {
    if (
      filter.value.startDateRange.dateFrom !==
      filter.value.startDateRange.dateTo
    ) {
      return false
    }
    return (
      formatDateToInputFormat(new Date()) === filter.value.startDateRange.dateTo
    )
  },
  set(v: boolean) {
    if (!v) {
      filter.value.startDateRange.dateFrom =
        filter.value.startDateRange.dateTo = ''
      return
    }
    filter.value.startDateRange.dateTo = filter.value.startDateRange.dateFrom =
      formatDateToInputFormat(new Date())
  }
})

const minToDate = computed(() => {
  if (!filter.value.startDateRange.dateFrom.length) {
    return filterData.value.startDate.minDate
  }
  return filter.value.startDateRange.dateFrom
})

const minFromDate = computed(() => {
  if (!filter.value.startDateRange.dateTo.length) {
    return filterData.value.startDate.minDate
  }
  return filter.value.startDateRange.dateTo
})
</script>
